<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Determine the appropriate <b>conjugate acid</b> for each of the compounds listed below.
      </p>

      <p class="mb-n3">
        a)
        <chemical-latex content="HSO4-(aq)" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.acid" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        b)
        <chemical-latex content="CO3^{2-}(aq)" />
      </p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.acid" />
        </div>
      </v-radio-group>

      <p class="mb-n3">
        c)
        <chemical-latex content="NH3(aq)" />
      </p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options3" :key="option.value">
          <v-radio class="d-inline-block mb-0" :value="option.value" />
          <stemble-latex :content="option.acid" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question131',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {acid: '$\\ce{SO4^{2-}}$', value: '1'},
        {acid: '$\\ce{SO4}$', value: '2'},
        {acid: '$\\ce{H2SO4^{2-}}$', value: '3'},
        {acid: '$\\ce{H2SO4}$', value: '4'},
      ],
      options2: [
        {acid: '$\\ce{H2CO3^{2-}}$', value: '1'},
        {acid: '$\\ce{H2CO3}$', value: '2'},
        {acid: '$\\ce{HCO3^{3-}}$', value: '3'},
        {acid: '$\\ce{HCO3^-}$', value: '4'},
      ],
      options3: [
        {acid: '$\\ce{NH4^+}$', value: '1'},
        {acid: '$\\ce{NH4^-}$', value: '2'},
        {acid: '$\\ce{NH2^+}$', value: '3'},
        {acid: '$\\ce{NH2^-}$', value: '4'},
      ],
    };
  },
};
</script>
